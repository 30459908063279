import React from 'react';
import './App.css';

const title = `███╗   ███╗ █████╗ ██╗  ██╗████████╗███████╗██████╗ ███████╗    ██╗███╗   ██╗ ██████╗
████╗ ████║██╔══██╗╚██╗██╔╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝    ██║████╗  ██║██╔════╝
██╔████╔██║███████║ ╚███╔╝    ██║   █████╗  ██████╔╝███████╗    ██║██╔██╗ ██║██║     
██║╚██╔╝██║██╔══██║ ██╔██╗    ██║   ██╔══╝  ██╔══██╗╚════██║    ██║██║╚██╗██║██║     
██║ ╚═╝ ██║██║  ██║██╔╝ ██╗   ██║   ███████╗██║  ██║███████║    ██║██║ ╚████║╚██████╗
╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝    ╚═╝╚═╝  ╚═══╝ ╚═════╝
                                                                                     `

function App() {
    return (
        <div className="App" style={{marginTop: "auto", display: "flex", justifyContent: "center"}}>
            <div style={{alignSelf: "center"}}>
           <pre style={{color: "rgb(206 198 198)"}}>
               {title}
           </pre>
            </div>
        </div>
    );
}

export default App;
